import React from 'react'
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import Layout from '../../components/Layout'

class Election2020Page extends React.Component {
  render() {
    const payasyougo = this.props.data.payasyougo
    const voterspass = this.props.data.voterspass
    const communitythree = this.props.data.communitythree
    const savethedate = this.props.data.savethedate
    return (
  <Layout>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h1 className="title is-size-1">2020 Directors Election</h1>
              <p>See documents and articles related to the 2020 directors election.</p>
              <hr />
              <div className="news-preview-container">

                <div className="news-preview-item">
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <a href="/assets/Westlake MUD No. 1 Amended and Restated Order Calling.pdf">AMENDED AND RESTATED ORDER CALLING DIRECTORS ELECTION<br />ORDEN ENMENDADA Y REFORMULADAPARA CONVOCAR
ELECCIÓN DE DIRECTORES<br />BẢN TU CHÍNH VÀ TRÌNH BÀY LẠI LỆNH YÊU CẦU TỔ CHỨC CUỘC BẦU CỬ CÁC
GIÁM ĐỐC<br />經修訂及重述的關於舉行董事選舉的命令</a>
                    <br /><small className="accent-color">October 19, 2020</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <a className="button is-white" href="/assets/Westlake MUD No. 1 Amended and Restated Order Calling.pdf">View</a>
                  </div>
                </div>
                
                <div className="news-preview-item">
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <a href="/assets/Notice of Voting Order.pdf">NOTICE OF VOTING ORDER PRIORITY<br />AVISO de PRIORIDAD de
                    ORDEN de VOTACIÓN<br />THÔNG BÁO VỀ QUYỀN ƯU TIÊN THỨ TỰ BỎ PHIẾU<br />通票次序優先權通知</a>
                    <br /><small className="accent-color">October 7, 2020</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <a className="button is-white" href="/assets/Notice of Voting Order.pdf">View</a>
                  </div>
                </div>

                <div className="news-preview-item">
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <a href="/assets/Westlake-2020-Directors-Election-Website-Form.pdf">BOARD OF DIRECTORS ELECTIONS<br />ELECCIONES DE LA JUNTA DIRECTIVA<br />CÁC CUỘC BẦU CỬ BAN GIÁM ĐỐC<br />董事會選舉</a>
                    <br /><small className="accent-color">September 15, 2020</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <a className="button is-white" href="/assets/Westlake-2020-Directors-Election-Website-Form.pdf">View</a>
                  </div>
                </div>

                <div className="news-preview-item">
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <a href="/assets/Notice of Appointed Agent - 2020">NOTICE OF APPOINTED AGENT<br />AVISO DE AGENTE NOMINADO<br />THÔNG BÁO VỀ VIỆC CHỈ ĐỊNH ĐẠI LÝ<br />任命代理人通知</a>
                    <br /><small className="accent-color">September 14, 2020</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <a className="button is-white" href="/assets/Notice of Appointed Agent - 2020">View</a>
                  </div>
                </div>

                <div className="news-preview-item">
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <a href="/assets/2020-Westlake Order Postponing Election">ORDER POSTPONING DIRECTORS ELECTION<br />ORDEN PARA POSPONER LA ELECCIÓN DE DIRECTORES<br />ALỆNH HOÃN TỔ CHỨC CUỘC BẦU CỬ CÁC GIÁM ĐỐC<br />關於推遲董事選舉</a>
                    <br /><small className="accent-color">April 15, 2020</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <a className="button is-white" href="/assets/2020-Westlake Order Postponing Election.pdf">View</a>
                  </div>
                </div>

              </div>
              <div className="section">
                <hr />
              <p><strong>English</strong><br />
              If you need this election document provided in Chinese, Vietnamese, or Spanish, please send us a message requesting the translated document using the <Link to="/contact">“Contact Us”</Link> link on this website.</p>

              <p><strong>Spanish</strong><br />
              Si necesita que le entreguen este documento sobre la elección en chino, vietnamita o español, envíenos un mensaje solicitando el documento traducido a través del enlace <Link to="/contact">“Contact Us”</Link> de este sitio web.</p>

              <p><strong>Vietnamese</strong><br />
              Nếu quý vị cần tài liệu bầu cử này bằng tiếng Hoa, tiếng Việt, hoặc tiếng Tây ban nha, vui lòng gửi thư yêu cầu cung cấp bản chuyển ngữ bằng cách sử dụng đường liên kết <Link to="/contact">"Contact Us"</Link> trên website này.</p>

              <p><strong>Chinese</strong><br />
              如果您需要這份選舉文件的中文、越南語或西班牙語版本，請使用本網站上的<Link to="/contact">「Contact Us」</Link>鏈接，向我們索取翻譯文本。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
}
}

export default Election2020Page

export const election2020PageQuery = graphql`
  query Election2020Query {
    payasyougo: imageSharp(original: {src: {regex: "/bond-financing-vs-pay-as-you-go-ENG/"}}) {
      fluid (maxWidth: 400){
        ...GatsbyImageSharpFluid
      }
    }
    voterspass: imageSharp(original: {src: {regex: "/DSC_2977/"}}) {
      fluid (maxWidth: 400){
        ...GatsbyImageSharpFluid
      }
    }
    communitythree: imageSharp(original: {src: {regex: "/community3/"}}) {
      fluid (maxWidth: 400){
        ...GatsbyImageSharpFluid
      }
    }
    savethedate: imageSharp(original: {src: {regex: "/save-the-date-oct-15/"}}) {
      fluid (maxWidth: 400){
        ...GatsbyImageSharpFluid
      }
    }
  }
`